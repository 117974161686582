import React, { useState, useEffect } from "react";
import Typography from '../components/Typography';
import Grid from "@mui/material/Grid";
import manejo1 from '../images/manejo/manejo-mitsubishi-01.jpg' 
import manejo2 from '../images/manejo/manejo-mitsubishi-02.jpg'
import manejo3 from '../images/manejo/manejo-mitsubishi-03.jpg'
import manejo4 from '../images/manejo/manejo-mitsubishi-04.jpg'
import interior1 from '../images/interior/interior-mitsubishi-01.jpg' 
import interior2 from '../images/interior/interior-mitsubishi-02.jpg'
import interior3 from '../images/interior/interior-mitsubishi-03.jpg'
import interior4 from '../images/interior/interior-mitsubishi-04.jpg'
import interior5 from '../images/interior/interior-mitsubishi-05.jpg' 
import interior6 from '../images/interior/interior-mitsubishi-06.jpg'
import interior7 from '../images/interior/interior-mitsubishi-07.jpg'
import interior8 from '../images/interior/interior-mitsubishi-08.jpg'
import exterior1 from '../images/exterior/exterior-mitsubishi-01.png'
import exterior2 from '../images/exterior/exterior-mitsubishi-02.jpg'
import exterior3 from '../images/exterior/exterior-mitsubishi-03.jpg'
import exterior4 from '../images/exterior/exterior-mitsubishi-04.jpg'
import AtributeMobile from "./atributeMobile";
import BgFooter from '../images/footer-mitsubishi.jpg'
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Navigation } from "swiper";
import Box from "@mui/material/Box";
import Form from "./Form";
import SectionTypeA from "./SectionTypeA";

const MenuProps = {
  PaperProps: {
    sx: {
      '& .MuiMenuItem-root': {
        fontSize: 10,
      },
    },
    style: {
      fontSize: 10,
    },
  },
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ProductCategories(props) {
  const { set, typeDialog, setTypeDialog, url } = props
  const [open, setOpen] = useState(false);
  const [validar, setValidar] = React.useState(false);
  const [asentamiento, setAsentamiento] = useState([])
  const [datadealers, setDataDealers] = useState([])
  const [valueGenero, setValueGenero] = React.useState('FEMENINO');
  const [valueTiempo, setValueTiempo] = React.useState('0 A 3 MESES');
  const [privacidad, setPrivacidad] = React.useState(false);
  const [terminos, setTerminos] = React.useState(false);
  const [mobile, setMobile] = useState(false)
  const [openAler, setOpenAlert] = React.useState(false);
  const [disabledButton, setDisabledButton] = React.useState(false);

  const isMobile = () => {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/BlackBerry/i)
    );
  };

  useEffect(() => {

    if (isMobile()) {
      setMobile(true)
    }

  }, []);

  const hasStock = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/status_stock`, {
        method: 'GET',
        headers: {
          accept: 'application/json',
        },
      });

      if (!response) {
        throw new Error(`Error! status: ${response.status}`);
      }

      const result = await response.json();
      
      console.log("response", result.status);
      //setDisabledButton(result.status);
      return result.status;
    } catch (err) {

      console.log(err);
	  //setDisabledButton(false);
	  return false;
    }
  }

  const handleClosAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  hasStock();

  return (
    <React.Fragment>
      <div class="wrapper">
        {/* INICIO SECCION DE MANEJO 1 */}
        <a id="manejo"> </a>
        <SectionTypeA 
          isMobile={isMobile} 
          img={manejo1} 
          title="Manejo"
          subtitle="La belleza de dominar el camino te la dan sus 7 modos de manejo.​"
          p1="No hay obstáculos para la Nueva Mitsubishi L200 GSR 2025, la combinación de tecnología y mecánica te dan el poder que necesitas para superar hasta el terreno más difícil.​​"
          p2=""
        />
        {/* FIN SECCION DE MANEJO 1 */}
        {/* INICIO SECCION DE MANEJO 2 */}
        <section id="sectionManejo2">
          <div class="section-content has-diagonal" style={{ marginTop: isMobile()?  '10px' :"40px", marginBottom: "160px" }}>
            <div style={{ margin: "1%" }}>
              <Grid container columnSpacing={1} style={{ marginTop: "0px", marginBottom: "0px", placeContent: "space-around" }}>
                <Grid item xs={12} md={12} lg={12} sx={{ alignItems: "center"}}>
                  <Typography style={{
                      fontSize: "clamp(2em, 5vw, 3rem)",
                      marginTop: +isMobile()?  '60px' :"0px",
                      color: "white",
                      textAlign: "center",
                      alignContent: "center",
                      marginBottom: "50px",
                      fontFamily: "mmcregular",
                      letterSpacing: "4",
                      lineHeight:"1em"
                    }} align="center" component="h1" color="white">
                      MANEJO
                  </Typography>
                </Grid>
                <Grid item xs={10} sm={6} xl={4}>
                  <Typography style={{
                    marginTop: isMobile()?  '-20px' :"-20px",
                    marginBottom: "60px",
                    fontFamily: "mmcregular",
                    lineHeight:"1.1em",
                    fontSize: "clamp(1.2rem, 5vw, 0.4rem)"     
                  }} align="center" component="h1" color="white">
                    
                  </Typography>
                </Grid>

                <Swiper
                  navigation={isMobile()? true : false}
                  slidesPerView={isMobile()? 1 : 3}
                  loop={true}
                  centeredSlides={false}
                  effect={"coverflow"}
                  modules={[Navigation]}
                  className={`mySwiper ${isMobile()? '' : 'swiper-no-swiping'}`}
                >
                  <SwiperSlide style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    margin:"0 auto"
                  }}>
                    <Grid container sx={{ alignItems: "start", margin:"0 auto"}}>
                      <Grid item xs={12} sm={12} md={12} sx={{ alignItems: "start"}}>
                        <center>
                          <Box
                            component="img"
                            src={manejo2}
                            alt="manejo2"
                            sx={{ maxWidth: isMobile()?  '90%' :"80%" }}
                          />
                          <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.9rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcmedium",
                              marginBottom: "20px",
                              marginTop: '15px',
                              letterSpacing: "0.1em",
                              maxWidth: isMobile()?  '90%' :"80%"
                            }}
                          >
                            El sistema Super Select 4WD-II, exclusivo de Mitsubishi Motors, hace que tengas una experiencia única de conducción en cualquier terreno con sus 4 modos de tracción.​ ​
                          </Typography>
                        </center>
                      </Grid>
                    </Grid>
                  </SwiperSlide>

                  <SwiperSlide style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    margin:"0 auto"
                  }}>
                    <Grid container sx={{ alignItems: "start", margin:"0 auto"}}>
                      <Grid item xs={12} sm={12} md={12} sx={{ alignItems: "start"}}>
                        <center>
                          <Box
                            component="img"
                            src={manejo3}
                            alt="manejo3"
                            sx={{ maxWidth: isMobile()?  '90%' :"80%" }}
                          />
                          <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.9rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcmedium",
                              marginBottom: "20px",
                              marginTop: '15px',
                              letterSpacing: "0.1em",
                              maxWidth: isMobile()?  '90%' :"80%"
                            }}
                          >
                            Experimenta su poderosa aceleración y su alto rendimiento con 210 Hp de potencia y 345 lb pie de torque.​ 
                          </Typography>
                        </center>
                      </Grid>
                    </Grid>
                  </SwiperSlide>

                  <SwiperSlide style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    margin:"0 auto"
                  }}>
                    <Grid container sx={{ alignItems: "start", margin:"0 auto"}}>
                      <Grid item xs={12} sm={12} md={12} sx={{ alignItems: "start"}}>
                        <center>
                          <Box
                            component="img"
                            src={manejo4}
                            alt="manejo4"
                            sx={{ maxWidth: isMobile()?  '90%' :"80%" }}
                          />
                          <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.9rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcmedium",
                              marginBottom: "20px",
                              marginTop: '15px',
                              letterSpacing: "0.1em",
                              maxWidth: isMobile()?  '90%' :"80%"
                            }}
                          >
                            Supera cualquier desafío con el Asistente de Arranque en Pendientes (HSA) que te ayuda a mantenerte seguro hasta el momento de la aceleración.​
                          </Typography>
                        </center>
                      </Grid>
                    </Grid>
                  </SwiperSlide>

                  {/* <SwiperSlide style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    margin:"0 auto"
                  }}>
                    <Grid container sx={{ alignItems: "start", margin:"0 auto"}}>
                      <Grid item xs={12} sm={12} md={12} sx={{ alignItems: "start"}}>
                        <center>
                          <Box
                            component="img"
                            src={modo5}
                            alt="modo5"
                            sx={{ maxWidth: isMobile()?  '90%' :"80%" }}
                          />
                          <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.9rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcmedium",
                              marginBottom: "20px",
                              marginTop: '15px',
                              letterSpacing: "0.1em",
                              maxWidth: isMobile()?  '90%' :"80%"
                            }}
                          >
                            7 MODOS DE MANEJO PARA UN SINFÍN DE POSIBILIDADES.
                          </Typography>
                        </center>
                      </Grid>
                    </Grid>
                  </SwiperSlide> */}

                  {/* <SwiperSlide style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    margin:"0 auto"
                  }}>
                    <Grid container sx={{ alignItems: "start", margin:"0 auto"}}>
                      <Grid item xs={12} sm={12} md={12} sx={{ alignItems: "start"}}>
                        <center>
                          <Box
                            component="img"
                            src={modo1}
                            alt="modo1"
                            sx={{ maxWidth: isMobile()?  '90%' :"80%" }}
                          />
                          <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.9rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcmedium",
                              marginBottom: "20px",
                              marginTop: '15px',
                              letterSpacing: "0.1em",
                              maxWidth: isMobile()?  '90%' :"80%"
                            }}
                          >
                            CON SUS CAPACIDADES 4X4 CON SUPER SELECT 4WD, L200 2025 NO CONOCE OBSTÁCULOS.
                          </Typography>
                        </center>
                      </Grid>
                    </Grid>
                  </SwiperSlide> */}
                </Swiper>
              </Grid>
            </div>
          </div>
          <div class="section-mask bg-option-2 has-parallax" style={{
            marginTop: '0px',
            background:
              "#000000"
          }}>
            <hr style={{ marginTop: "-2px", height: "3px" }} color="#cf0303" />
          </div>
        </section >
        {/* FIN SECCION DE MANEJO 2 */}
        {/* INICIO SECCION DE INTERIOR 1 */}
        <a id="interior"> </a>
        <SectionTypeA 
          isMobile={isMobile} 
          img={interior1} 
          title="Interior"
          subtitle="Los desafíos nunca se vieron tan cómodos.​​"
          p1="​Con un diseño ergonómico, ofrece una comodidad inigualable en una pickup, su exclusivo diseño bitono con textura está pensado para la comodidad de todos en viajes largos o cortos."
          p2="Equipado con tecnología japonesa de punta, crea un ambiente perfecto para explorar, trabajar o para viajar en familia sin importar cual sea el terreno.​​​"
        />
        {/* FIN SECCION DE INTERIOR 1 */}
        {/* INICIO SECCION DE INTERIOR 2 */}
        <section id="sectionInterior">
          <div class="section-content has-diagonal" style={{ marginTop: isMobile()?  '10px' :"40px", marginBottom: "140px" }}>
            <div style={{ margin: "1%" }}>
              <Grid container columnSpacing={1} style={{ marginTop: "0px", marginBottom: "-20px", placeContent: "space-around" }}>
                <Grid item xs={10} md={12} lg={12} sx={{ alignItems: "center"}}>
                  <Typography style={{
                      fontSize: "clamp(2em, 5vw, 3rem)",
                      marginTop: +isMobile()?  '60px' :"0px",
                      color: "white",
                      textAlign: "center",
                      alignContent: "center",
                      marginBottom: "50px",
                      fontFamily: "mmcregular",
                      letterSpacing: "4",
                      lineHeight:"1em"
                    }} align="center" component="h1" color="white">
                      INTERIOR
                  </Typography>
                </Grid>
                <Swiper
                  navigation={true}
                  slidesPerView={isMobile()? 1 : 3}
                  loop={true}
                  centeredSlides={false}
                  effect={"coverflow"}
                  modules={[Navigation]}
                  className="mySwiper"
                >
                  <SwiperSlide style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    margin:"0 auto"
                  }}>
                    <Grid container sx={{ alignItems: "start", margin:"0 auto"}}>
                      <Grid item xs={12} sm={12} md={12} sx={{ alignItems: "start"}}>
                        <center>
                          <Box
                            component="img"
                            src={interior2}
                            alt="interior2"
                            sx={{ maxWidth: isMobile()?  '90%' :"80%" }}
                          />
                          <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.9rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcmedium",
                              marginBottom: "20px",
                              marginTop: '15px',
                              letterSpacing: "0.1em",
                              maxWidth: isMobile()?  '90%' :"80%"
                            }}
                          >
                            Sus exclusivas texturas interiores han sido diseñadas para otorgar un toque único que te hará sobresalir.​
                          </Typography>
                        </center>
                      </Grid>    
                    </Grid>
                  </SwiperSlide>
                  <SwiperSlide style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    margin:"0 auto"
                  }}>
                    <Grid container sx={{ alignItems: "start", margin:"0 auto"}}>
                      <Grid item xs={12} sm={12} md={12} sx={{ alignItems: "start"}}>
                        <center>
                          <Box
                            component="img"
                            src={interior3}
                            alt="interior3"
                            sx={{ maxWidth: isMobile()?  '90%' :"80%" }}
                          />
                          <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.9rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcmedium",
                              marginBottom: "20px",
                              marginTop: '15px',
                              letterSpacing: "0.1em",
                              maxWidth: isMobile()?  '90%' :"80%"
                            }}
                          >
                            Gracias a sus múltiples conexiones, ten energía en cualquier lugar.
                          </Typography>
                        </center>
                      </Grid>
                    </Grid>
                  </SwiperSlide>
                  <SwiperSlide style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    margin:"0 auto"
                  }}>
                    <Grid container sx={{ alignItems: "start", margin:"0 auto"}}>
                      <Grid item xs={12} sm={12} md={12} sx={{ alignItems: "start"}}>
                        <center>
                          <Box
                            component="img"
                            src={interior4}
                            alt="interior4"
                            sx={{ maxWidth: isMobile()?  '90%' :"80%" }}
                          />
                          <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.9rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcmedium",
                              marginBottom: "20px",
                              marginTop: '15px',
                              letterSpacing: "0.1em",
                              maxWidth: isMobile()?  '90%' :"80%"
                            }}
                          >
                            El conductor y copiloto pueden ajustar su asiento en múltiples posiciones.​
                          </Typography>
                        </center>
                      </Grid>
                    </Grid>
                  </SwiperSlide>
                  <SwiperSlide style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    margin:"0 auto"
                  }}>
                    <Grid container sx={{ alignItems: "start", margin:"0 auto"}}>
                      <Grid item xs={12} sm={12} md={12} sx={{ alignItems: "start"}}>
                        <center>
                          <Box
                            component="img"
                            src={interior5}
                            alt="interior5"
                            sx={{ maxWidth: isMobile()?  '90%' :"80%" }}
                          />
                          <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.9rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcmedium",
                              marginBottom: "20px",
                              marginTop: '15px',
                              letterSpacing: "0.1em",
                              maxWidth: isMobile()?  '90%' :"80%"
                            }}
                          >
                            Con espacios pensados y hechos a la medida para guardar tus pertenencias.​
                          </Typography>
                        </center>
                      </Grid>
                    </Grid>
                  </SwiperSlide>
                  <SwiperSlide style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    margin:"0 auto"
                  }}>
                    <Grid container sx={{ alignItems: "start", margin:"0 auto"}}>
                      <Grid item xs={12} sm={12} md={12} sx={{ alignItems: "start"}}>
                        <center>
                          <Box
                            component="img"
                            src={interior6}
                            alt="interior6"
                            sx={{ maxWidth: isMobile()?  '90%' :"80%" }}
                          />
                          <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.9rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcmedium",
                              marginBottom: "20px",
                              marginTop: '15px',
                              letterSpacing: "0.1em",
                              maxWidth: isMobile()?  '90%' :"80%"
                            }}
                          >
                            Su segunda fila ha sido diseñada para que todos viajen cómodos. ​
                          </Typography>
                        </center>
                      </Grid>
                    </Grid>
                  </SwiperSlide>
                  <SwiperSlide style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    margin:"0 auto"
                  }}>
                    <Grid container sx={{ alignItems: "start", margin:"0 auto"}}>
                      <Grid item xs={12} sm={12} md={12} sx={{ alignItems: "start"}}>
                        <center>
                          <Box
                            component="img"
                            src={interior7}
                            alt="interior7"
                            sx={{ maxWidth: isMobile()?  '90%' :"80%" }}
                          />

                          <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.9rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcmedium",
                              marginBottom: "20px",
                              marginTop: '15px',
                              letterSpacing: "0.1em",
                              maxWidth: isMobile()?  '90%' :"80%"
                            }}
                          >
                            Espacio de almacenamiento para llevar tus bebidas favoritas.
                          </Typography>
                        </center>
                      </Grid>
                    </Grid>
                  </SwiperSlide>          
                </Swiper>
              </Grid>
            </div>
          </div>
          <div class="section-mask bg-option-2 has-parallax" style={{
            marginTop: '0px',
            background:
              "#000000"
          }}>
            <hr style={{ marginTop: "-2px", height: "3px" }} color="#cf0303" />
          </div>
        </section >
        {/* FIN SECCION DE INTERIOR 2 */}
        {/* INICIO SECCION DE INTERIOR 3 */}
        <SectionTypeA 
          isMobile={isMobile} 
          img={interior8} 
          title="Interior"
          subtitle="Una estrella de pantalla grande.​"
          p1="Equipada con una pantalla de Infoentretenimiento táctil de 9”, accede a funciones como Apple CarPlay ™ y Android Auto ™ para estar siempre conectado de manera inalámbrica.​​​"
          p2=""
        />
        {/* FIN SECCION DE INTERIOR 3 */}
        {/* INICIO SECCION DE EXTERIOR 1 */}
        <a id="exterior"> </a>
        <SectionTypeA 
          isMobile={isMobile} 
          img={exterior1} 
          title="Exterior"
          subtitle="Experimenta el poder a primera vista.​"
          p1="La Nueva Mitsubishi L200 GSR 2025 cuenta con elementos que se fusionan para crear una pickup perfecta para cualquier aventura.​​"
          p2="Su diseño único se complementa con accesorios en color negro que arrastran todas las miradas en tu camino.​"
        />
        {/* FIN SECCION DE EXTERIOR 1 */}
        {/* INICIO SECCION DE MANEJO 2 */}
        <section id="sectionManejo2">
          <div class="section-content has-diagonal" style={{ marginTop: isMobile()?  '10px' :"40px", marginBottom: "160px" }}>
            <div style={{ margin: "1%" }}>
              <Grid container columnSpacing={1} style={{ marginTop: "0px", marginBottom: "0px", placeContent: "space-around" }}>
                <Grid item xs={12} md={12} lg={12} sx={{ alignItems: "center"}}>
                  <Typography style={{
                      fontSize: "clamp(2em, 5vw, 3rem)",
                      marginTop: +isMobile()?  '60px' :"0px",
                      color: "white",
                      textAlign: "center",
                      alignContent: "center",
                      marginBottom: "50px",
                      fontFamily: "mmcregular",
                      letterSpacing: "4",
                      lineHeight:"1em"
                    }} align="center" component="h1" color="white">
                      EXTERIOR
                  </Typography>
                </Grid>
                <Grid item xs={10} sm={6} xl={4}>
                  <Typography style={{
                    marginTop: isMobile()?  '-20px' :"-20px",
                    marginBottom: "60px",
                    fontFamily: "mmcregular",
                    lineHeight:"1.1em",
                    fontSize: "clamp(1.2rem, 5vw, 0.4rem)"     
                  }} align="center" component="h1" color="white">
                    
                  </Typography>
                </Grid>

                <Swiper
                  navigation={false}
                  slidesPerView={isMobile()? 1 : 3}
                  loop={false}
                  centeredSlides={false}
                  effect={"coverflow"}
                  //modules={[Navigation]}
                  className="mySwiper swiper-no-swiping"
                >
                  <SwiperSlide style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    margin:"0 auto"
                  }}>
                    <Grid container sx={{ alignItems: "start", margin:"0 auto"}}>
                      <Grid item xs={12} sm={12} md={12} sx={{ alignItems: "start"}}>
                        <center>
                          <Box
                            component="img"
                            src={exterior2}
                            alt="exterior2"
                            sx={{ maxWidth: isMobile()?  '90%' :"80%" }}
                          />
                          <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.9rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcmedium",
                              marginBottom: "20px",
                              marginTop: '15px',
                              letterSpacing: "0.1em",
                              maxWidth: isMobile()?  '90%' :"80%"
                            }}
                          >
                            El diseño Dynamic Shield exclusivo de Mitsubishi Motors te otorga la seguridad que necesitas. ​
                          </Typography>
                        </center>
                      </Grid>
                    </Grid>
                  </SwiperSlide>

                  <SwiperSlide style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    margin:"0 auto"
                  }}>
                    <Grid container sx={{ alignItems: "start", margin:"0 auto"}}>
                      <Grid item xs={12} sm={12} md={12} sx={{ alignItems: "start"}}>
                        <center>
                          <Box
                            component="img"
                            src={exterior3}
                            alt="exterior3"
                            sx={{ maxWidth: isMobile()?  '90%' :"80%" }}
                          />
                          <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.9rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcmedium",
                              marginBottom: "20px",
                              marginTop: '15px',
                              letterSpacing: "0.1em",
                              maxWidth: isMobile()?  '90%' :"80%"
                            }}
                          >
                            La amplia batea reforzada te permite llevar con seguridad las cosas que necesites para tu siguiente aventura.
                          </Typography>
                        </center>
                      </Grid>
                    </Grid>
                  </SwiperSlide>

                  <SwiperSlide style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    margin:"0 auto"
                  }}>
                    <Grid container sx={{ alignItems: "start", margin:"0 auto"}}>
                      <Grid item xs={12} sm={12} md={12} sx={{ alignItems: "start"}}>
                        <center>
                          <Box
                            component="img"
                            src={exterior4}
                            alt="exterior4"
                            sx={{ maxWidth: isMobile()?  '90%' :"80%" }}
                          />
                          <Typography
                            style={{
                              color: "white",
                              fontSize: "clamp(0.9rem, 3vw, 1.2rem)",
                              fontStyle: "normal",
                              fontFamily: "mmcmedium",
                              marginBottom: "20px",
                              marginTop: '15px',
                              letterSpacing: "0.1em",
                              maxWidth: isMobile()?  '90%' :"80%"
                            }}
                          >
                            Supera cualquier desafío en tu camino con sus rines de aluminio de 18” en color negro.
                          </Typography>
                        </center>
                      </Grid>
                    </Grid>
                  </SwiperSlide>
                </Swiper>
              </Grid>
            </div>
          </div>
          <div class="section-mask bg-option-2 has-parallax" style={{
            marginTop: '0px',
            background:
              "#000000"
          }}>
            <hr style={{ marginTop: "-2px", height: "3px" }} color="#cf0303" />
          </div>
        </section >
        {/* FIN SECCION DE MANEJO 2 */}


        {/* seccion 5 */}
		    <a id="apartala">.</a>
        <section id="apartala1" style={{ marginTop: "-80px" }}>
          <div class="section-content has-diagonal" style={{ marginTop: '100px', marginBottom: "100px" }}>
            <Grid container spacing={2} style={{ marginTop: +isMobile()?  '60px' :"80px", marginBottom: +isMobile()?  '60px' :"120px", placeContent: "space-around" }}>
              <Grid item xs={10} md={12} lg={10} sx={{ alignItems: "center"}}>
                <Typography style={{
                    fontSize: "clamp(2em, 5vw, 3rem)",
                    marginTop: 0,
                    color: "white",
                    textAlign: "center",
                    alignContent: "center",
                    marginBottom: "0",
                    fontFamily: "mmcregular",
                    letterSpacing: "4",
                    lineHeight:"1em"
                  }} align="center" component="h1" color="white">
                    REINVENTAMOS NUESTRA PODEROSA PICKUP Y LA FUSIONAMOS CON NUESTRA TECNOLOGÍA JAPONESA PARA OFRECERTE EL MEJOR DESEMPEÑO.​
​<br/><br/>
CREANDO LA 4X4 QUE TE HARÁ SOBRESALIR EN CUALQUIER CAMINO, ARRASTRANDO TODAS LAS MIRADAS CON SU PODER.​
​<br/><br/>
OBTÉNLA PRIMERO QUE NADIE.
                </Typography>
              </Grid>
            </Grid>
            <Form set={set} setTypeDialog={setTypeDialog} typeDialog={typeDialog} currentUrl={url}/>
          </div>
          <div class="section-mask bg-option-2 has-parallax" style={{
            marginTop: '100px',
            background:
              " linear-gradient(74deg, rgba(51,48,51,1) 0%, rgba(23,21,21,1) 19%, rgba(21,21,21,1) 61%, rgba(21,21,21,1) 84%, rgba(39,39,40,1) 100%)"
          }}>
            <hr style={{ position: "relative", marginTop: "-2px", height: "3px" }} color="#cf0303" />
          </div>
        </section >
        <section>
          <div style={{
            position: "relative",
            marginTop: '2px',
            background: "black"
          }}>
            <img
              src={BgFooter}
              alt="increase priority"
              style={{
                marginTop: "-8px", color: "#4D4D4D", maxWidth: '100%', height: "auto"
              }}
            />
          </div>
        </section>
      </div>
      

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openAler}
        autoHideDuration={4000}
        onClose={handleClosAlert}
      >
        <Alert onClose={handleClosAlert} severity="error" sx={{ width: "100%" }}>
          Llene los campos requeridos.
        </Alert>
      </Snackbar>
    </React.Fragment >
  );
}
